<template>
  <div>
    <table-list
      class="tableList"
      :title="tableTitle"
      :loading="loading"
      :search-form="searchForm(this)"
      :data="list"
      :btns="btns(this)"
      :columns="columns(this)"
      :operates="operates(this)"
      :pager="pager"
      @search="onSearch"
      @selectionChange="handleSelectionChange"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></table-list>
  </div>
</template>

<script>
//表格标题
const tableTitle = '考试列表'
// 搜索框配置
const searchForm = _this => [
  {
    label: '员工姓名',
    prop: 'name',
  },
  {
    label: '员工手机号',
    prop: 'mobile',
  },
  {
    label: '所在班级',
    prop: 'classId',
    type: 'select',
    children: _this.classList.map(item => ({ value: item.classId, label: item.className })),
  },
  {
    label: '考试时间',
    prop: 'time',
    type: 'picker',
  },
]
// 顶部按钮
const btns = _this => [
  // {
  //   label: '导出全部考生答案',
  //   type: 'primary',
  //   method: _this.handleAllStudentsAnwser,
  // },
  {
    label: '导出全部成绩',
    type: 'primary',
    method: _this.handleAllResult,
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'studentName',
    label: '员工姓名',
  },
  {
    prop: 'mobile',
    label: '员工手机号',
  },
  {
    prop: 'className',
    label: '所在班级',
  },
  {
    prop: 'scoreTotal',
    label: '考试总分',
  },
  {
    prop: 'finalScore',
    label: '得分',
  },
  {
    prop: 'examStartTime',
    label: '考试时间',
  },
]
// 表格操作按钮配置
const operates = _this => [
  {
    label: '详情',
    method: _this.handleDetail,
  },
]
import { testResult, testResultClass, testExport } from '@/api/examination'
import TableList from '@/components/TableList'
import to from 'await-to'
export default {
  components: {
    TableList,
  },
  data() {
    return {
      tableTitle,
      searchForm,
      btns,
      columns,
      operates,
      loading: false,
      list: [],
      classList: [],
      select: [],
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      queryParams: {},
      examId: this.$route.query.examId,
    }
  },
  created() {
    this.testResultData()
    this.testResultClassData()
  },
  methods: {
    async testResultData() {
      this.loading = true
      const { current, size } = this.pager
      const examId = this.examId
      const [res, err] = await to(testResult({ current, size, examId, ...this.queryParams }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.loading = false
      this.list = res.data.list
      this.pager.total = res.data.total
    },
    async testResultClassData() {
      const [res, err] = await to(testResultClass({ examId: this.examId }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.classList = res.data
    },
    async handleAllResult() {
      const [res, err] = await to(
        testExport({ examId: this.examId, ...this.queryParams }, { responseType: 'blob' }),
      )
      if (err) return this.$message({ type: 'error', message: err.msg })
      let blob = new Blob([res], {
        type: 'application/vnd.ms-excel',
      })
      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = url
      a.download = '考试成绩.xlsx'
      a.click()
      window.URL.revokeObjectURL(url)
    },
    async handleAllStudentsAnwser() {
      console.log('全部考生答案')
    },
    handleDetail(row) {
      this.$router.push({ path: 'testDetail', query: { id: row.id } })
    },
    handleSelectionChange(val) {
      this.select = val
    },
    // 查询按钮
    onSearch(queryParams) {
      this.pager.current = 1
      if (queryParams.time && queryParams.time.length !== 0) {
        queryParams.startTime = queryParams.time[0]
        queryParams.endTime = queryParams.time[1]
      } else {
        queryParams.startTime = ''
        queryParams.endTime = ''
      }
      this.queryParams = queryParams
      this.testResultData()
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.testResultData()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.testResultData()
    },
  },
}
</script>

<style scoped></style>
